#application {
    display: grid;
    grid-template-columns: 50px 1fr;
    min-height: 100vh;
}

#application.sidebarOpen {
    grid-template-columns: 200px 1fr;
    transition: grid-template-columns 0.1s ease-in;
}

.dash__container {
    padding: 40px;
}

@media only screen and (max-width: 640px ) {
    #application {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr
    }

    .dash__container {
        padding: 40px 20px;
    }
}