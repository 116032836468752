.project_list_container {
    border: 1px solid #ececec;
    border-radius: 8px;
    max-width: 800px;
}

.table__header {
    padding: 20px;
}

.project_list_container .table__container {
    width: 100%;
}

.table__container_tabs {
    display: flex;
    padding: 0px 20px;
    border-bottom: 1px solid #ececec;
}

.category {
    margin-right: 40px;
    font-weight: 600;
    cursor: pointer;
    padding-bottom: 10px;
}

.category.active {
    border-bottom: 2px solid #253340;
}

.table__container_body td,
.table__container_body th {
    padding: 10px 20px;
}

.table__container_body th:nth-of-type(1) {
    width: 38%
}

.table__container_body th:nth-of-type(2) {
    width: 17%;
}

.table__container_body th:nth-of-type(3) {
    width: 20%;
}

.table__container_body th:nth-of-type(4) {
    width: 25%;
}

.table__container_body table {
    width: 100%;
    padding: 20px 20px 20px 0;
}

.table__container_body thead {
    text-align: left;
}

.table__container_body tbody tr {
    height: 40px;
    /* cursor: pointer; */
}

.table__container_body tbody tr:hover {
    box-shadow: -1px 3px 10px rgb(0 0 0 / 20%);
    cursor: pointer;
}

.table__column_initial {
    display: flex;
}

.initial_container {
    position: relative;
    z-index: 300;
}

.initial {
    display: inline-block;
    background: #f6e7ea;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    margin-right: 4px;
}

.table__container_body tbody .tooltip {
    display: none;
    position: absolute;
    background: grey;
    font-size: 10px;
    padding: 2px 4px;
    border-radius: 2px;
    bottom: -16px;
    z-index: 300;
    left: -24px;
    color: white;
}

.table__container_body tbody .tooltip.active {
    display: inline-block;
}

@media only screen and (max-width: 640px ) {
    .project_list_container {
        font-size: 0.8em;
    }

    .table__container_body th:nth-of-type(2) {
        width: 20%;
    }

    .table__container_body td,
    .table__container_body th {
        padding: 10px;
    }
}