.project__charts {
    display: flex;
    flex-wrap: wrap;
    max-width: 1280px;
}

.chart__container {
    margin-right: 32px;
}

.project__breakdown {
    display: flex;
    margin-bottom: 100px;
    flex-wrap: wrap;
}

.participant {
    position: relative;
    width: 350px;
    margin-top: 40px;
    margin-right: 50px;
    margin-bottom: 64px;
}

.participant:last-of-type {
    margin-right: 0;
}

.participant table {
    width: 100%;
    border-collapse: collapse;
}

.participant .table_tooltip {
    position: absolute;
    bottom: 12px;
    right: 0;
}

.participant .table_tooltip:hover > .tooltip_text {
    display: inline-block;
}

.participant .tooltip_text {
    display: none;
    position: absolute;
    top: 0;
}

.participant .helper {
    bottom: -38px;
    right: -130px;
    font-size: 12px;
    width: 250px;
}

.participant .helper.open {
    display: inline-block;
}

.participant th {
    padding: 10px 0px;
}

.participant table th:first-of-type  {
    text-align: left;
    width: 170px;
}

.participant table th:nth-of-type(2) {
    text-align: right;
}

.participant tbody th {
    font-weight: normal;
}

.participant table tfoot {
    border-top: 1px solid var(--main-color);
}

.participant tfoot td {
    padding-top: 10px;
}

.participant .with_symbol {
    display: flex;
    justify-content: space-between;
}