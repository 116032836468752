.dash-header {
    padding: 20px 40px 20px 20px;
}

.dash-header__container {
    display: flex;
    justify-content: flex-end;
}

.searchbar {
    display: inline-block;
    position: relative;
    height: 50px;
    margin-right: 20px;
}

.searchbar input {
    background: var(--primary-bg-color);
    border-radius: 4px;
    border: none;
    font-size: 16px;
    padding: 10px 40px 10px 20px;
    height: 100%;
    margin-top: 0;
}

.searchbar input::placeholder {
    color: var(--main-color);
    font-size: 12px;
}

.searchbar i {
    position: absolute;
    right: 18px;
    top: 18px;
}

.notifications {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    background: var(--primary-bg-color);
    border-radius: 4px;
    cursor: pointer;
    margin-right: 40px;
}

.notifications__alarm {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #c67f69;
    font-size: 10px;
} 

.profile {
    height: 50px;
    display: flex;
}

.profile__image {
    height: 100%;
}

.profile__image img {
    height: 100%;
    border-radius: 50%;
}

.profile__information {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 16px;
    font-weight: 100;
    font-size: 14px;
    width: 132px;
}

.profile__information p {
    width: 100%;
}

.profile_name {
    margin-bottom: -16px;
    font-weight: 600;
}

.profile_company {
    font-size: 12px;
}

@media only screen and (max-width: 740px) {
    .profile__information {
        display: none;
    }
}

@media only screen and (max-width: 1240px) {
    .dash-header__container{
        justify-content: inherit;
    }
}