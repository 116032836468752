.public {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.public section {
    max-width: 640px;
    width: 460px;
}

.public header,
.public main,
.public footer,
.public form {
    width: 100%;
}
.login {
    display: flex;
    margin-top: 20px;
}

.form-group.checkbox {
    display: flex;
    align-items: center;
}

.form-group.checkbox input[type='checkbox'] {
    width: 20px;
    margin: 0;
    margin-left: 20px;
}

.forgot {
    border: 1px solid gray;
    background: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
}