.user__container {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 20px 20px 20px 20px;

}

.user__picture,
.user__status {
    width: 50px;
}

.user__name {
    width: 250px;
    padding-left: 20px;
}

.user__name p:nth-of-type(1) {
    font-weight: bold;
}

.user__name p:nth-of-type(2) {
    font-size: 12px;
}

.user__role {
    width: 200px;
}

.user__status {
    width: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user__status p:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 174, 0);
    border-radius: 100%;
    width: 8px;
    height: 8px;
    margin-right: 10px;
}

.user__status p:nth-of-type(1).inactive {
    background-color: var(--red-color);
}

.user__status p:nth-of-type(1).pending {
    background-color: var(--danger-color);
}

.user__action {
    font-size: 22px;
    width: 50px;
    cursor: pointer;
    position: relative;
}

.user__action:hover > .helper {
    display: inline-block;
}

.user__action:hover > i {
    color: var(--danger-color)
}

.user__action.nohover {
    cursor: inherit
}

.user__action.nohover:hover > i {
    color: inherit;
}

.user__container div:nth-of-type(1) img {
    width: 100%;
    border-radius: 50%;
}

.helper {
    display: none;
    font-size: 10px;
    position: absolute;
    background: black;
    border-radius: 4px;
    color: white;
    padding: 2px 4px;
    bottom: -32px;
    width: 150px;
    text-align: center;
    right: -32px;
}

.success__message i {
    color: var(--success-color)
}

@media only screen and (max-width: 640px ) {

    .user__role, .user__status, .user__action {
        display: none;
    } 

 }